<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" ref="logFilterForm" label-width="70px" size="medium" class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="会员名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入会员名称" />
        </el-form-item>
        <el-form-item :label="`${form.tab === 'position'?'晋升':'会内'}职务：`" prop="promote_ids">
          <el-cascader v-model="form.promote_ids" :options="parentPositionList" :props="props"
            placeholder="请选择">
          </el-cascader>
        </el-form-item>
        <el-form-item label="性别：" prop="gender">
          <el-select v-model="gender" placeholder="请选择">
            <el-option v-for="item in genderList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="籍贯：" prop="native_place">
          <el-cascader :options="placeList" v-model="form.native_place" :props="optionProp"
                       clearable @change="handleChange" />
        </el-form-item>
        <!-- TODO: 原职务 -->
        <el-form-item v-if="form.tab === 'position'" label="原职务：" prop="origin_ids">
          <el-cascader v-model="form.origin_ids" :options="parentPositionList" :props="props"
            placeholder="请选择">
          </el-cascader>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import { positionLevel } from "../../api/member/member-list";

const nativePlace = require("../../assets/jsons/address.json");

export default {
  name: "ListFilter",
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    ids: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      parentPositionList: [],
      genderList: [
        { id: 1, name: "男" },
        { id: 2, name: "女" },
      ],
      placeList: nativePlace,
      optionProp: {
        expandTrigger: "hover",
        label: "label",
        value: "label",
      },
    };
  },
  methods: {
    // 点击选择籍贯
    handleChange(e) {
      this.form.native_place = e.join(",");
    },
    // 重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    getPositionLevel() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
        .then((res) => {
          this.parentPositionList = res.data;
        })
        .catch(() => {});
    },
  },
  created() {
    this.getPositionLevel();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    position_id: {
      get() {
        return this.form.position_id == -1 ? "" : this.form.position_id;
      },
      set(val) {
        this.form.position_id = val;
      },
    },
    organization_id: {
      get() {
        return this.form.organization_id == -1 ? "" : this.form.organization_id;
      },
      set(val) {
        this.form.organization_id = val;
      },
    },
    gender: {
      get() {
        return this.form.gender == -1 ? "" : this.form.gender;
      },
      set(val) {
        this.form.gender = val;
      },
    },
  },
  components: {
    LayoutFilter,
  },
};
</script>
