<template>
  <div class="announce-list list-page">
    <el-tabs style="margin-bottom: 20px" value="list" @tab-click="handleTabClick">
      <el-tab-pane :label="tab.label" :name="tab.name" v-for="(tab, index) in tabArr" :key="index">
      </el-tab-pane>
    </el-tabs>
    <ListFilter v-model="filterForm" :uploadFilter="ok" :ids="selectArr"
      :parentPositionList="OrganizationList" />
    <list-tabs v-model="filterForm.tab" :isLoading="loading" :tabs="tabArr1" @tab-click="handleClick">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button type="success" size="small" @click="handlePass(1)">通过</el-button>
        <el-button type="warning" size="small" @click="handlePass(0)">不通过</el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="small" @click="handlePassRow(scope.row, 1)" type="text">
                通过
              </el-button>
              <el-button size="small" @click="handlePassRow(scope.row, 0)" type="text">不通过
              </el-button>
              <el-button size="small" @click="toDetailList(scope.row.id)" type="text">查看
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <el-table-column v-for="(th, index) in thead[filterForm.tab]" :key="index" :prop="th.prop"
          :label="th.label" :min-width="th.minWidth || ''" :sortable="th.sortable" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- 头像 -->
            <list-image v-if="th.prop === 'avatar' && scope.row.avatar" :src="scope.row[th.prop]" :width="32"
              :height="32"></list-image>
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <no-pass-dialog v-model="dialog.show" :tab="pageData.tab" :paid="dialog.paid" :ids="dialog.ids"
      @refresh="getList(filterForm)"></no-pass-dialog>
  </div>
</template>

  <script>
import { doCheck, getList } from "../../api/announce/list";
import ListFilter from "../../components/Announce/ListFilter";
import Pagination from "../../../../base/components/Default/Pagination";
import NoPassDialog from "../../components/Announce/NoPassDialog";
import ListImage from "../../../../base/components/List/ListImage";
import { parentToList } from "../../api/meeting-post/post-list";

export default {
  components: {
    ListImage,
    NoPassDialog,
    ListFilter,
    Pagination,
  },
  data() {
    return {
      loading: true,
      tabArr: [
        { label: "公示列表", name: "list" },
        { label: "其他配置", name: "config" },
      ],
      // 筛选对象
      filterForm: {
        tab: "member", // 当前筛选tab
        // organization_id: -1,
        // position_id: -1,
        origin_ids: [], //原职务
        promote_ids: [], //晋升职务
        native_place: "",
        positionIds: "",
        gender: -1,
        keyword: "", // 关键字
        page_size: 15, // 每页数据数
      },
      filters: {
        member: null,
        position: null,
      },
      tabArr1: [
        { label: "正式会员公示", name: "member" },
        { label: "职务晋升公示", name: "position" },
      ],
      selectArr: [],
      pageData: {
        page_size: 15,
        tab: "member",
      },
      thead: {
        member: [
          { label: "ID", prop: "id", minWidth: 120 },
          { label: "头像", prop: "avatar", minWidth: 80 },
          { label: "会员名称", prop: "nickname", minWidth: 110 },
          { label: "性别", prop: "gender_text", minWidth: 60 },
          { label: "籍贯", prop: "native_place", minWidth: 110 },
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          { label: "公示时间", prop: "public_expire_time", minWidth: 200 },
          {
            label: "赞同人数",
            prop: "dissent_yes_count",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "异议人数",
            prop: "dissent_no_count",
            minWidth: 100,
            sortable: true,
          },
        ],
        position: [
          { label: "ID", prop: "id", minWidth: 120 },
          { label: "头像", prop: "avatar", minWidth: 80 },
          { label: "会员名称", prop: "nickname", minWidth: 110 },
          { label: "性别", prop: "gender", minWidth: 60 },
          { label: "籍贯", prop: "native_place", minWidth: 110 },
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          { label: "原职务", prop: "public_title_name", minWidth: 150 },
          { label: "晋升职务", prop: "title_name", minWidth: 150 },
          { label: "公示时间", prop: "public_expire_time", minWidth: 200 },
          {
            label: "赞同人数",
            prop: "dissent_yes_count",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "异议人数",
            prop: "dissent_no_count",
            minWidth: 100,
            sortable: true,
          },
        ],
      },
      tbody: [],
      dialog: {
        ids: [],
        show: false,
        paid: false,
      },
      OrganizationList: [],
    };
  },
  created() {
    this.getList(this.pageData);
    this.getParentToList();
  },
  methods: {
    // 获取架构列表
    getParentToList() {
      parentToList({ crr_level: -1 })
        .then((res) => {
          const { data } = res;
          data.map((item) => {
            item.children = [];
          });
          this.OrganizationList = data;
        })
        .catch((err) => {});
    },
    handlePassRow(row, status) {
      switch (this.filterForm.tab) {
        case "member":
          if (status === 1) {
            // TODO: 点击通过时弹确认提示：确定通过该会员吗？
            this.$confirm("确定通过该会员吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "info",
            })
              .then(() => {
                this.loading = true;
                doCheck(row.id)
                  .then((res) => {
                    this.$message.success(res.msg);
                    this.getList(this.filterForm);
                    this.loading = false;
                  })
                  .catch((err) => {
                    this.loading = false;
                  });
              })
              .catch(() => {});
          } else {
            /* 缴费状态（0：未缴费，1：已缴费，2：无需缴费，3：需补差价，4：需退还差价） */
            this.UnCheckedDialog([row.id], {
              title: row.title_name,
              paid: row.pay_status === 1,
            });
          }
          break;
        case "position":
          if (status === 1) {
            // TODO: 点击通过时弹确认提示：确定通过该会员吗？
            this.$confirm("确定通过该会员吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "info",
            })
              .then(() => {
                this.loading = true;
                doCheck(row.id)
                  .then((res) => {
                    this.$message.success(res.msg);
                    this.getList(this.filterForm);
                    this.loading = false;
                  })
                  .catch((err) => {
                    this.loading = false;
                  });
              })
              .catch(() => {});
          } else {
            this.UnCheckedDialog([row.id], {
              title: row.title_name,
              paid: row.pay_status === 1,
            });
          }
          break;
      }
    },
    // 批量通过/不通过
    handlePass(status) {
      if (this.selectArr.length) {
        switch (this.filterForm.tab) {
          case "member":
            if (status === 1) {
              // TODO: 点击通过时弹确认提示：确定通过该会员吗？
              this.$confirm("确定通过这些会员吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "info",
              })
                .then(() => {
                  this.loading = true;
                  doCheck(this.selectArr)
                    .then((res) => {
                      this.$message.success(res.msg);
                      this.getList(this.filterForm);
                      this.loading = false;
                    })
                    .catch((err) => {
                      this.loading = false;
                    });
                })
                .catch(() => {});
            } else {
              this.UnCheckedDialog(this.selectArr, {});
            }
            break;
          case "position":
            if (status === 1) {
              // TODO: 点击通过时弹确认提示：确定通过该会员吗？
              this.$confirm("确定通过这些会员吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "info",
              })
                .then(() => {})
                .catch(() => {});
            } else {
              this.UnCheckedDialog(this.selectArr, {});
            }
            break;
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 不通过弹窗
    UnCheckedDialog(ids, type) {
      this.dialog.ids = ids;
      this.dialog.show = true;
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getList(pageData);
    },
    handleTabClick(e) {
      if (e.paneName === "config") {
        this.$router.push({ name: "CycUserPublicConfig" });
      }
    },
    getList(query) {
      this.loading = true;
      getList(query)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getList({ ...this.filterForm, ...pageData });
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          // organization_id: -1,
          // position_id: -1,
          origin_ids: [], //原职务
          promote_ids: [], //晋升职务
          gender: -1,
          keyword: "", // 名称
          tab: e.name, // 当前筛选tab
          page_size: this.pageData.per_page, // 每页数据量
        };
      }
      this.getList(this.filterForm);
    },
    toDetailList(id) {
      this.$router.push({ name: "CycUserPublicDetail", params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
