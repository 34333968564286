import api from '@/base/utils/request';

export const getList = (data) => {
  return api({
    url: '/admin/cyc/user_public/index',
    method: 'post',
    data
  })
}

export const doCheck = (id) => {
  return api({
    url: '/admin/cyc/user_public/check',
    method: 'post',
    data: {id}
  })
}

export const doNoCheck = (data) => {
  return api({
    url: '/admin/cyc/user_public/noCheck',
    method: 'post',
    data
  })
}
