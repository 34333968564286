<template>
  <el-dialog :visible="value" :title="tab === 'member'?'正式会员公示期不通过':'职务晋升不通过'"
             width="441px" class="dialog-vertical" @close="close">
    <div v-loading="loading">
      <template v-if="tab === 'member'">
        <div class="desc">
          选中的会员已缴纳会费，请勾选是否退还会费
        </div>
        <el-checkbox v-model="isReturnFee">退还会费</el-checkbox>
      </template>
      <el-form v-if="value" label-position="top" class="small-form">
        <el-form-item label="不通过原因">
          <el-input v-model="why" v-focus type="textarea" :maxlength="50" show-word-limit placeholder="请填写原因"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="confirm" :loading="loading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {doNoCheck} from "../../api/announce/list";

  export default {
    name: "NoPassDialog",
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true,
      },
      tab: {
        type: String,
        default: 'member'
      },
      paid: {
        type: Boolean,
        default: false
      },
      ids: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        loading: false,
        isReturnFee: false,
        why: ""
      }
    },
    methods: {
      close() {
        this.$emit('input', false)
      },
      confirm() {
        this.loading = true;
        let checkParams = {
          id: this.ids
        }
        if (this.tab === 'member') {
          checkParams.why = this.why;
          checkParams.is_fund = this.isReturnFee;
        }
        doNoCheck(checkParams).then(res => {
          this.$message.success(res.msg);
          this.loading = false;
          this.close()
          this.$emit('refresh')
        }).catch(err => {
          this.loading = false;
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .desc {
    margin-top: 10px;
  }

  .el-checkbox {
    margin-top: 16px;
  }

  .el-form {
    margin-top: 20px;
  }

  .el-textarea {
    max-width: 100%;
    height: 80px;
  }
</style>
