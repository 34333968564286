import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

// 获取会内职务列表
export const postList = data => {
  return api({
    url: "/admin/cyc/position/index",
    method: "post",
    data
  });
};

// 永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc/position/del",
    method: "post",
    data
  });
};

// 获取所属组织列表
export const parentToList = data => {
  return api({
    url: "/admin/cyc/Organization/parentToList",
    method: "post",
    data
  });
};

// 迁移职务
export const MovePosition = data => {
  return api({
    url: "/admin/cyc/position/move",
    method: "post",
    data
  });
};

// 获取某组织会内职务
export const PostOptions = data => {
  return api({
    url: "/admin/cyc/position/organizationPositions",
    method: "post",
    data
  });
};

// 获取上级组织列表
export const ParentList = data => {
  return api({
    url: "/admin/cyc/Organization/OrganizationsTree",
    method: "post",
    data
  });
};

//会内职务列表导出
export const documentPositionList = data => {
  return fetchFile({
    url: "/admin/cyc/position/export",
    method: "post",
    data,
    download: true
  });
};